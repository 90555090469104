document.addEventListener('DOMContentLoaded', () => {
  Array.from(document.getElementsByClassName('at-toggler toggle-open')).forEach((toggleEl) => {
    toggleEl.addEventListener('click', () => {
      const target = document.getElementById(toggleEl.dataset.atTarget);

      if (!target) { return; }
      target.style.display = 'block';
    });
  });

  Array.from(document.getElementsByClassName('at-toggler toggle-close')).forEach((toggleEl) => {
    toggleEl.addEventListener('click', () => {
      const target = document.getElementById(toggleEl.dataset.atTarget);

      if (!target) { return; }
      target.style.display = 'none';
    });
  });
});
